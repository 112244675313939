import { graphql } from "gatsby"
import React, { useEffect, useState, useRef } from "react"
import gsap from "gsap"
import ReactFullpage from "@fullpage/react-fullpage"
import Hero from "../components/Hero"
import OtherHero from "../components/OtherHero"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"

const IndexPage = ({ data, location }) => {

  // set preload and timeout state
  const [preloader, setPreloader] = useState(true)
  const [timer, setTimer] = useState(2)

  const id = useRef(null)

  const clear = () => {
    window.clearInterval(id.current);
    setPreloader(false);
  }

  const SEL = 'section';
  const SECTION_SEL = `.${SEL}`;

  const afterLoadHandler = (origin, destination, direction) => {
    console.log(origin.index, direction)


    if (origin.index === 0 && direction === "down") {
      // gsap.to('#madeby',{opacity: 0, duration: 1})
      // gsap.to('#rise',{opacity: 0, duration: 1, transformOrigin: "left center", scale: 1})
      gsap.from('#madeby', { opacity: 0, duration: 5 })
      gsap.from('#rise', { opacity: 0, duration: 5 })

    }

    if (origin.index === 1 && direction === "up") {
      gsap.to('#madeby', { opacity: 1, duration: 1 })
      // gsap.to('#rise',{opacity: 1, duration: 1, transformOrigin: "left center", scale: 1.35})
    }
  }

  useEffect(() => {
    const madeByTitle = document.getElementById("madeby")
    const riseTitle = document.getElementById("rise")

    console.log("madeby", madeByTitle)
    console.log("rise", riseTitle)
  })


  useEffect(() => {
    id.current = window.setInterval(() => {
      setTimer((timer) => timer - 1)
    }, 1000)

    gsap.from('#preload-madeby', { opacity: 0, duration: 1 })
    gsap.from('#preload-rise', { opacity: 0, duration: 1.5 })
    const animation = gsap.timeline();
    animation
      .to('#preload-madeby', { duration: 1.5, x: -150, y: 50 })



  }, [])

  useEffect(() => {
    if (timer === 0) {
      clear();
    }
  }, [timer])

  return (
    <>
      {preloader ?
        <div className="h-screen flex items-center justify-center flex-col bg-mbr_orange text-white">
          <h2 id="preload-madeby">Made By</h2>
          <h1 id="preload-rise">RISE</h1>
        </div>
        :
        <Layout path={location.pathname} >
          <SiteMetadata title="Home" description="Portfolio of MADE BY RISE" />
          {typeof window !== 'undefined' && (
            <ReactFullpage
              //fullpage options
              licenseKey={'B233B019-4D414E29-BCAC0DDE-975324E5'}
              sectionSelector={SECTION_SEL}
              afterLoad={afterLoadHandler}

              render={comp => (
                <ReactFullpage.Wrapper>
                  <div id="index-container" className="section">
                    <Hero content={data.main.edges[0]} />
                  </div>
                  <div className="section">
                    <OtherHero content={data.second.edges[0]} lastHero={false} />
                  </div>
                  <div className="section">
                    <OtherHero content={data.third.edges[0]} lastHero={false} />
                  </div>
                  <div className="section">
                    <OtherHero content={data.forth.edges[0]} lastHero={true} />
                  </div>
                </ReactFullpage.Wrapper>
              )}
            />
          )}
        </Layout>
      }
    </>
  )
}

export default IndexPage

export const query = graphql`
  query LandingQuery {
    main: allContentfulPageHero(filter: {title: {eq: "Landing > First Hero"}}) {
      edges {
        node {
          backgroundImage {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          titleSvg {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    second: allContentfulPageHero(filter: {title: {eq: "Landing > Second Hero"}}) {
      edges {
        node {
          contentParagraph
          backgroundImage {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          titleSvg {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    third: allContentfulPageHero(filter: {title: {eq: "Landing > Third Hero"}}) {
      edges {
        node {
          contentParagraph
          backgroundImage {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          titleSvg {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    forth: allContentfulPageHero(filter: {title: {eq: "Landing > Fourth Hero"}}) {
      edges {
        node {
          contentParagraph
          backgroundImage {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          titleSvg {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
