import React from "react"
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'

const Hero = ({content}) => {
  return (
    <div className="hero-area">
      <BackgroundImage
        Tag="section"
        style = {{
          position: "absolute",
          width: "100vw",
          top: "0",
          height: "100vh",
          objectFit: "cover",
          opacity: "0.5",
          zIndex: "-1"
        }}
        fluid={content.node.backgroundImage.fluid}
        backgroundColor={`#040e18`}
      >
        <div className="w-screen absolute bottom-0 text-white mx-auto">
          <h2 id="madeby" className="hero-main-madeby md:text-7xl">MADE BY</h2>
          <div id="rise">
            <Img style={{height: "100%"}} imgStyle={{height: "110%"}} fluid={content.node.titleSvg.fluid} />
          </div>
        </div>
      </BackgroundImage>
    </div>      
)}

export default Hero
